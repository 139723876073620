<template>
  <div class="header">
   <div class="logo">
      <img width="100" :src="logo"/>
      <div class="border-left padding-left-10 margin-left-10 bold text-nowrap">价格管理平台</div>
   </div>
   <div class="header-main margin-left-10">
     <div class="nav">
        <div :class="{'active':$route.matched.some(val=>{return val.name=='sciExpert'})}" data-path="/sciExpert/assignment/week" @click="jump($event)"><span class="text">卓创赋值</span></div>
        <div  v-if="$store.state.loginResSCI.isOut" :class="{'active':$route.matched.some(val=>{return val.name=='sciOutDivisors'})}" data-path="/sciOutDivisors/assignment/week" @click="jump($event)"><span class="text">卓创外生因子赋值</span></div>
     </div>
     <div class="tools text-nowrap">
       
        <el-popover placement="bottom-end" trigger="hover">
          <div slot="reference" class="margin-right-20 margin-left-20 link hover-opacity">
            <el-avatar :size="28" icon="el-icon-user-solid" style="display:block"
              :src="avatar"
            ></el-avatar>
          </div>
          <div v-if="$store.state.loginResSCI.token">
            <div class="flex-row justify-between align-items-center margin-top-5">
              <div class="text-nowrap margin-right-5">你好，{{$store.state.loginResSCI.userName}}</div>
              <span class="link-text" @click="logout">退出</span>
            </div>
            <el-divider></el-divider>
            <div class="flex-row justify-between align-items-center">
              <span class="color-sub">主题模式：</span>
              <el-switch
                @change="toggleTheme"
                v-model="valueTheme"
                active-value="dark"
                inactive-value="light"
                inactive-color="#ccc"
                active-text="深色"
                inactive-text="浅色">
              </el-switch>
            </div>
            
          </div>
        </el-popover>
     </div>
   </div>
  </div>
</template>

<script>

import avatar from "@/assets/images/photo.png";
import logo from "@/assets/images/logo.png";
export default {
  name: 'HeaderSCI',
  components: {

  },
  data() {
    return {
      logo:logo,
      avatar:avatar,
      valueTheme:this.$store.state.theme,
    }
  },
  methods:{
    jump: function(event,_blank){
      let path = event.currentTarget.dataset.path
      if(_blank){
        let routeData = this.$router.resolve({ 
          path:path
        });
        window.open(routeData.href, '_blank');   
      }else{
        if(path == this.$route.path){
          this.$router.replace({
                path: '/redirect',
                query: {path}
            })
        }else {
          this.$router.push({
            path: path
          })
        }
        }
    },
    
    logout(){
      //弹窗提示
      this.$confirm('您要退出登录吗?', '退出登录', {
        customClass:"custom-msgbox",
        confirmButtonText: '继续退出',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {

        try{
          localStorage.removeItem("loginResSCI");
          localStorage.removeItem("loginStampSCI");
        }catch{
          //
        }
       
        this.$router.replace({
          path: '/sciLogin',
          query:{
            redirect:this.$route.fullPath
          }
        })
        
      })
      .catch(() => {});
    },

    //切换主题
    toggleTheme:function(){
      try{
          localStorage.setItem('theme',this.valueTheme)
      }catch{
          return
      }
      this.$store.commit('theme', this.valueTheme)
      window.document.documentElement.setAttribute( "data-theme", this.$store.state.theme );
    }
  },
  created(){
    
  },
  mounted(){

  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  @import './header.scss';
</style>
<style>
html[data-theme=dark] .header{
    background: #283142;
}
.header .el-switch__label.is-active{
  color: #aeceff;
}
.header .el-switch__label{
  color: #fff;
}
</style>