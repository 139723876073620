<template>
  <div>
    <HeaderSCI class="header"></HeaderSCI>
    <router-view class="container"></router-view>
  </div>
</template>

<script>
import HeaderSCI from '@/components/header/HeaderSCI.vue'
export default {
  components: { HeaderSCI },
  name: 'Default',
  data() {
    return {
      
    }
  },
  methods:{

  },
  mounted(){

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header{
    position: relative;
    z-index: 2;
    overflow: auto;
  }
  .container{
    width: 100%;
    height: calc(100vh - 55px);
    overflow: auto;
    position: relative;
    z-index: 1;
  }
</style>
